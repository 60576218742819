import {
  ethers
} from "ethers";

import store from "@/store";

console.log(ethers.utils.toUtf8CodePoints('中国'));




let ContractAddress;
let Abi;
async function start(){
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let res1= await signer.getChainId();
  let net_address='address'
    if (res1=='1337') {
      net_address='address'
    }else{
      net_address='address_bsc'
    }


   
    const { default: Contract } = await import(`@/wallet/${net_address}/LogicMarket.logicMarketAddress`);
    ContractAddress = Contract.address;
    Abi = Contract.abi;


};

import { approve, allowance} from "./tokenAuto";


export async function isAllowance() {
  let isAllowance=await allowance(ContractAddress);
 
  return isAllowance;
}
export async function toApprove() {
  let isApprove=await approve(ContractAddress,Abi);
  return isApprove;
}


export async function rps(roleid,psNum) {
 console.log("封装boss得到参数购买体力",roleid);
 console.log("封装boss得到参数购买体力",psNum)
 await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let address= await signer.getAddress();
  let res = await myContract.rps(roleid,psNum);
  if(res.wait()){
    await res.wait();
  }
  
  if(res){
      
      return res;
  }else{
    return false
  }
}


export  async function gemMaintenance(roleid,day) {
  await start();
console.log("封装boss得到参数购买天数",roleid);
console.log("封装boss得到参数购买天数",day)
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let address= await signer.getAddress();
let res = await myContract.gemMaintenance(roleid,day);

if(res.wait()){
  await res.wait();
}

if(res){
    
    return res;
}else{
   return false
}
}

export  async function getRoleMarketList() {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);


 
  let res = await myContract.getRoleMarketList();
  
  if(res){
      
      return res;
  }else{
     return false
  }
}

export  async function getArmsMarketList() {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);


 
  let res = await myContract.getArmsMarketList();
  
  if(res){
      
      return res;
  }else{
     return false
  }
}


export  async function getGemstoneMarketList() {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);


 
  let res = await myContract.getGemstoneMarketList();
  
  if(res){
      
      return res;
  }else{
     return false
  }
}

export async function sellRole(roleNumber,amount) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let res = await myContract.sellRole(roleNumber,  ethers.utils.parseUnits(amount));
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
  
 // 

  
}

export async function sellArms(roleNumber,amount) {
  await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let res = await myContract.sellArms(roleNumber, ethers.utils.parseUnits(amount));
if(res.wait()){
  await res.wait();
 
}
if(res){
    
    return res;
}else{
  return false
}

// 


}

export async function sellGemstone(roleNumber,amount) {
  await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let res = await myContract.sellGemstone(roleNumber,  ethers.utils.parseUnits(amount));
if(res.wait()){
  await res.wait();
 
}
if(res){
    
    return res;
}else{
  return false
}

// 


}


export  async function challengeBoss(bossLevel,roleid) {
  console.log("挑战boss",bossLevel);
  console.log("挑战boss",roleid);
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

  let address= await signer.getAddress();
  let max=9999;
  let min=1000;
  let num=Math.floor(Math.random()*(max-min))+min;
  let res = await myContract.challengeBoss(bossLevel,roleid,num);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
     return false
  }
}

export async function buyRole(roleNumber,seller) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let res = await myContract.buyRole(roleNumber, seller);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
  
 // 

  
}

export async function buyGemstone(roleNumber,seller) {
  await start();
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner();

let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

let res = await myContract.buyGemstone(roleNumber, seller);
if(res.wait()){
  await res.wait();
 
}
if(res){
    
    return res;
}else{
  return false
}

// 


}

export async function buyArms(roleNumber,seller) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);

  let res = await myContract.buyArms(roleNumber, seller);
  if(res.wait()){
    await res.wait();
  
  }
  if(res){
      
      return res;
  }else{
    return false
  }

  // 


}



//下架角色
export async function takeDownRole(roleId) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let res = await myContract.takeDownRole(roleId);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
  
 // 

  
}

//下架武器
export async function takeDownArms(roleId) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let res = await myContract.takeDownArms(roleId);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
  
 // 

  
}

//下架宝石
export async function takeDownGemstone(roleId) {
  await start();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();

  let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
 
  let res = await myContract.takeDownGemstone(roleId);
  if(res.wait()){
    await res.wait();
   
  }
  if(res){
      
      return res;
  }else{
    return false
  }
  
 // 

  
}